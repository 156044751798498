import { Link } from "gatsby"
import React, { useState, useEffect } from 'react'
import Logo from "../assets/img/logo-w4m.png"
import LogoWhite from "../assets/img/logo-w4m-white.png"
import FacebookWhite from "../assets/img/icons/facebook-w.svg"
import FacebookBlack from "../assets/img/icons/facebook-b.svg"
import InstagramWhite from "../assets/img/icons/instagram-w.svg"
import InstagramBlack from "../assets/img/icons/instagram-b.svg"
import TwitterWhite from "../assets/img/icons/twitter-w.svg"
import TwitterBlack from "../assets/img/icons/twitter-b.svg"
import LinkedInWhite from "../assets/img/icons/linkedin-w.svg"
import LinkedInBlack from "../assets/img/icons/linkedin-b.svg"
// import GoogleWhite from "../assets/img/icons/google-w.svg"
// import GoogleBlack from "../assets/img/icons/google-b.svg"


const Header = ({ color }) => {

  let homePage = "nav-links";
  let hamburgerColor = "hamburger";
  let headerBg = "header-wrapper";

  if (color === "white") {
    homePage = "nav-links white";
    hamburgerColor = "hamburger white"
    headerBg = "header-wrapper white"
  }

  useEffect(() => {
    if (window.innerWidth >= 1200) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    const header = document.getElementById('header-wrapper');

    document.addEventListener("scroll", function () {
      if (window.pageYOffset > 0) {
        header.classList.add('scrolled')
      } else if (window.pageYOffset === 0) {
        header.classList.remove('scrolled')
      }
    })
  });

  const [mobile, setMobile] = useState(false);

  const [showMenu, setMenu] = useState(false);
  const menuHandler = function () {
    setMenu(showMenu => !showMenu);
  }


  return (
    <div className={headerBg} id="header-wrapper">
      <header className={showMenu ? 'header-menu open' : "header-menu"}>
        <div className="logo">

          {
            color === "white"
              ? <Link to="/"><img src={LogoWhite} alt=""></img></Link>
              : <Link to="/"><img src={Logo} alt=""></img></Link>
          }

        </div>
        <div className={homePage}>
          <ul>
            <li><Link to="/about" activeClassName="active">Wie zijn wij</Link></li>
            <li><Link to="/titles" activeClassName="active">Onze titels</Link></li>
            <li><Link to="/subscriptions" activeClassName="active">Mediabureau</Link></li>
            <li><Link to="/onze-opdrachtgevers" activeClassName="active">Onze opdrachtgevers</Link></li>
            <li><Link to="/contact" activeClassName="active">Contact</Link></li>
            <span></span>
          </ul>
          <div className="social-links">
            {mobile === true ? <a href="https://www.facebook.com/Work4Media" target="_blank" rel="noreferrer"><img src={FacebookBlack} alt=""></img></a> : color === "white" ? <a href="https://www.facebook.com/Work4Media" target="_blank" rel="noreferrer"><img src={FacebookWhite} alt=""></img></a> : <a href="https://www.facebook.com/Work4Media" target="_blank" rel="noreferrer"><img src={FacebookBlack} alt=""></img></a>}
            {mobile === true ? <a href="https://www.instagram.com/industriebouw_/" target="_blank" rel="noreferrer"><img src={InstagramBlack} alt=""></img></a> : color === "white" ? <a href="https://www.instagram.com/industriebouw_/" target="_blank" rel="noreferrer"><img src={InstagramWhite} alt=""></img></a> : <a href="https://www.instagram.com/industriebouw_/" target="_blank" rel="noreferrer"><img src={InstagramBlack} alt=""></img></a>}
            {mobile === true ? <a href="https://twitter.com/media_work4" target="_blank" rel="noreferrer"><img src={TwitterBlack} alt=""></img></a> : color === "white" ? <a href="https://twitter.com/media_work4" target="_blank" rel="noreferrer"><img src={TwitterWhite} alt=""></img></a> : <a href="https://twitter.com/media_work4" target="_blank" rel="noreferrer"><img src={TwitterBlack} alt=""></img></a>}
            {mobile === true ? <a href="https://www.linkedin.com/company/29019397/admin/" target="_blank" rel="noreferrer"><img src={LinkedInBlack} alt=""></img></a> : color === "white" ? <a href="https://www.linkedin.com/company/29019397/admin/" target="_blank" rel="noreferrer"><img src={LinkedInWhite} alt=""></img></a> : <a href="https://www.linkedin.com/company/29019397/admin/" target="_blank" rel="noreferrer"><img src={LinkedInBlack} alt=""></img></a>}
          </div>
        </div>
        <div className={hamburgerColor} onClick={menuHandler}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
    </div>
  );

}

export default Header
