/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import "../style/main.scss";

import Header from "./header"

const Layout = ({ children, color }) => {

  return (
    <>
      <Header color={color}/>
      <main>{children}</main>
      {/* <footer>
        © {new Date().getFullYear()}
      </footer> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
